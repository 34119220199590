<template>
  <div>
    <v-dialog v-model="isShowDetail" max-width="950" persistent>
      <v-card height="700" max-height="700">
        <v-card-title>
                    <span>
ข้อมูลของร้านค้า
                    </span>
          <v-spacer></v-spacer>
          <v-select :items="typeList"
                    v-model="typeSelection"
                    no-data-text="ไม่มีข้อมูล"
                    label="เลือกรายการ" filled
                    dense
                    @change="checkType"
                    style="max-width: 300px"
                    item-text="text"
                    item-value="value"
                    hide-details
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
            v-if="typeSelection==='product'"
            :headers='headers'
            :items='dataList'
            :items-per-page='itemsPerPage'
            :loading='loading'
            no-data-text='ไม่มีข้อมูล !'
            loading-text='กำลังโหลดข้อมูล...'
            :footer-props='footer'
            no-results-text='ไม่มีข้อมูล !'
            class='elevation-1'
        >
          <template #[`item.product_id`]='{ index }'>
            {{ index + 1 }}
          </template>
          <template #[`item.product_image`]='{ item }'>
            <v-img :src='item.product_image' class='elevation-4 rounded-lg my-1' width='50'
                   height='50'>

            </v-img>
          </template>

          <template #[`item.shop_id`]='{ item }'>
            <v-chip
                :color="item.shop_id == 1?'primary':'info'"
                :class="item.shop_id==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
                small
            >
              {{ item.shop_id == 1 ? `Data center` : `ร้านค้า` }}
            </v-chip>
          </template>
        </v-data-table>
        <v-data-table
            :headers='headers'
            :items='dataList'
            v-if="typeSelection==='warehouse'"
            :items-per-page='itemsPerPage'
            :loading='loading'
            no-data-text='ไม่มีข้อมูล !'
            loading-text='กำลังโหลดข้อมูล...'
            :footer-props='footer'
            no-results-text='ไม่มีข้อมูล !'
            class='elevation-1'
        >
          <template #[`item.store_id`]='{ index }'>
            {{ index + 1 }}
          </template>
        </v-data-table>
        <v-data-table
            :headers='headers'
            :items='dataList'
            v-if="typeSelection==='employee'"
            :items-per-page='itemsPerPage'
            :loading='loading'
            no-data-text='ไม่มีข้อมูล !'
            :footer-props='footer'
            no-results-text='ไม่มีข้อมูล !'
            loading-text='กำลังโหลดข้อมูล...'
            class='elevation-1'
        >
          <template #[`item.user_id`]='{ index }'>
            {{ index + 1 }}
          </template>
          <template #[`item.user_active`]='{ item }'>
            <StatusBlock :status='String(item.user_active)'/>
          </template>
          <template #[`item.actions`]='{ item }'>
            <v-tooltip top>
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                    color='primary'
                    icon
                    v-bind='attrs'
                    @click='menuList=item.menus;isFixedRole = true'
                    v-on='on'
                >
                  <v-icon>{{ mdiTextBoxCheck }}</v-icon>
                </v-btn>
              </template>
              <span>กำหนดสิทธิ์</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                    color='primary'
                    icon
                    v-bind='attrs'
                    @click='userDetail = item;isEdit = true'
                    v-on='on'
                >
                  <v-icon>{{ mdiPencilOutline }}</v-icon>
                </v-btn>
              </template>
              <span>แก้ไข</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                    color='primary'
                    icon
                    v-bind='attrs'
                    @click='userDetail = item;isUpdateStatus = true'
                    v-on='on'
                >
                  <v-icon>{{ item.user_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.user_active == 1 ? `ระงับ` : `ปกติ` }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-divider class="my-2"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="$emit(`update:isShowDetail`,false)">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {api} from "@/services/api"
import {ref, watch} from "@vue/composition-api";
import footerDataTables from "@/fake-db/footerDataTables.json";

export default {
  model: {
    prop: 'isShowDetail',
    event: 'update:isShowDetail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    shopId: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  setup(props, {root, emit}) {
    const typeList = ref([
      {text: 'รายการสินค้า', value: 'product'},
      {text: 'คลังสินค้า', value: 'warehouse'},
      {text: 'พนักงาน', value: 'employee'},
    ])
    const typeSelection = ref('product')
    const headers = ref([])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const footer = ref(footerDataTables)
    const loading = ref(false)
    const checkType = () => {
      if (typeSelection.value === 'product') {
        headers.value = [
          {
            text: '#',
            value: 'product_id',
            width: '50',
          },
          {
            text: 'รหัสสินค้า',
            value: 'product_code',
            width: '120',
            align: 'center',
          },
          {
            text: 'รูปสินค้า',
            value: 'product_image',
            width: '110',
            align: 'center',
          },
          {
            text: 'ชื่อสินค้า',
            value: 'product_name',
            width: '200',
          },
          {
            text: 'หมวดหมู่สินค้า',
            value: 'product_name',
            width: '150',
          },
          {
            text: 'น้ำหนัก',
            value: 'product_weight',
            width: '100',
            align: 'end',
          },
          {
            text: 'หน่วย',
            value: 'product_unit',
            width: '100',
          },

          {
            text: ' ร้านค้า',
            value: 'shop_id',
            width: '100',
            align: 'center',
          },
        ]
      } else if (typeSelection.value === 'warehouse') {
        headers.value = [
          {
            text: '#',
            value: 'store_id',
            width: '50',
          },
          {
            text: 'รหัส',
            value: 'store_code',
            align: 'center',
          },
          {
            text: 'ชื่อคลังสินค้า/สาขา',
            value: 'store_name',
            width: 200,
          },
          {
            text: 'มูลค่ารวมคลังสินค้า',
            value: 'store_price_total',
            align: 'end',
            width: 200,
          },
          {
            text: 'จำนวนทั้งหมด/ชิ้น',
            value: 'store_balance_total',
            align: 'end',
            width: 200,
          },
        ]
      } else {
        headers.value = [
          {
            text: '#',
            value: 'user_id',
            width: '50',
            align: 'center',
          },
          {
            text: 'ชื่อ - นามสกุล',
            value: 'user_fullname',
            width: '200',
          },
          {
            text: 'Username',
            value: 'username',
            width: '200',
          },
          {
            text: 'ร้านค้า',
            value: 'shop_name',
            width: '300',
          },
        ]
      }
      getShopData()
    }
    const getShopData = async () => {
      loading.value = true
      let currentPath = typeSelection.value === 'product' ? 'productInShop' : typeSelection.value === 'warehouse' ? 'stores' : 'users'
      api.get({
        path: `/admin/${currentPath}`,
        param: `?shop_id=${props.shopId}`,
      }).then(({data}) => {
        dataList.value = data
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log('err', err)
      })
    }

    watch(() => props.isShowDetail, value => {
      if (value) {
        checkType()
      }
    })

    return {
      typeList,
      typeSelection,
      checkType,
      headers,
      itemsPerPage,
      dataList,
      loading,
      footer,
    }
  },

}
</script>

<style scoped>

</style>
