<template>
  <div>
    <v-dialog v-model='isShowDetail' persistent width='400'>
      <v-card>
        <v-card-title class='px-2'>
          รายละเอียดร้านค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-row class='mx-2 my-2'>
          <v-col cols='6' class='text-right font-weight-semibold'>รหัสร้านค้า :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_id }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>ชื่อร้านค้า :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_name }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>ประเภทร้านค้า :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_type }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>สาขา :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_branch }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>เบอร์โทรศัพท์ :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_phone }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>ที่อยู่ :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_full_address }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>เลขผู้เสียภาษี :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_tax_number }}</v-col>
          <v-col cols='6' class='text-right font-weight-semibold'>FAX :</v-col>
          <v-col cols='6'>{{ shopDataLocal.shop_fax_number }}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='secondary' outlined @click='$emit(`update:isShowDetail`)'>ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'

export default {
  model: {
    prop: 'isShowDetail',
    event: 'update:isShowDetail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    shopData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, {root, emit}) {
    const shopDataLocal = ref({})

    watch(() => props.isShowDetail, value => {
      if (value) {

        shopDataLocal.value = JSON.parse(JSON.stringify(props.shopData))
        console.log('shopDataLocal', shopDataLocal.value)
      }
    })


    return {shopDataLocal}
  },

}
</script>

<style scoped>

</style>
