<template>
  <div>
    <v-dialog v-model='isAddNewShop' max-width='800' persistent>
      <v-card>
        <v-card-title>
          เพิ่มร้านค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewShop' @submit.prevent='addNewShop'>
          <v-card-text>
            <v-row class="mt-2">
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_name'
                              filled dense
                              hide-details='auto'
                              label='ชื่อร้านค้า'
                              placeholder='ชื่อร้านค้า'
                              :rules='[required]'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_phone' filled dense
                              hide-details='auto'
                              label='เบอร์โทรศัพท์'
                              v-mask="['###-####-####']"
                              placeholder='เบอร์โทรศัพท์'
                              :rules='[required]'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_type'
                              filled dense
                              hide-details='auto'
                              label='ประเภทร้านค้า'
                              placeholder='ประเภทร้านค้า'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_product_type'
                              filled dense
                              hide-details='auto'
                              label='ประเภทสินค้าร้านค้า'
                              placeholder='ประเภทสินค้าร้านค้า'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_branch' filled dense
                              hide-details='auto'
                              label='สโตร์'
                              placeholder='สโตร์'
                >
                </v-text-field>
              </v-col>

              <v-col cols='12' md='6'>
                <v-text-field
                    v-model='address.PROVINCE_NAME'
                    label='จังหวัด'
                    placeholder="จังหวัด"
                    hide-details='auto'
                    dense
                    filled
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field
                    v-model='address.DISTRICT_NAME'
                    label='อำเภอ'
                    placeholder="อำเภอ"
                    hide-details='auto'
                    dense
                    filled
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field
                    v-model='address.SUB_DISTRICT_NAME'
                    label='ตำบล'
                    hide-details='auto'
                    placeholder=""
                    dense
                    filled
                >
                </v-text-field>
              </v-col>

              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_address' filled dense
                              hide-details='auto'
                              label='ที่อยู่'
                              placeholder='ที่อยู่'
                              :rules='[required]'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field
                    v-model='address.ZIPCODE'
                    label='รหัสไปรษณีย์'
                    placeholder="รหัสไปรษณีย์"
                    hide-details='auto'
                    dense
                    filled
                >

                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_tax_number' filled dense
                              hide-details='auto'
                              label='รหัสผู้เสียภาษี'
                              v-mask="['###-###-####']"
                              placeholder='รหัสผู้เสียภาษี'
                >
                </v-text-field>
              </v-col>
              <v-col cols='12' md='6'>
                <v-text-field v-model='shopData.shop_fax_number' filled dense
                              hide-details='auto'
                              label='เบอร์แฟกซ์'
                              placeholder='เบอร์แฟกซ์'
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>บันทึก
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isAddNewShop`,false)'>ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import location_new from '@/fake-db/location_new.json'
import { api } from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewShop',
    event: 'update:isAddNewShop',
  },
  props: {
    isAddNewShop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const loading = ref(false)
    const locationList = ref(location_new)
    const address = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })
    const shopData = ref(
        {
          shop_name: '',
          shop_phone: '',
          shop_subdistrict: '',
          shop_province: '',
          shop_district: '',
          shop_zipcode: '',
          shop_address: '',
          shop_type: '',
          shop_branch: '',
          shop_tax_number: '',
          shop_fax_number: '',
          shop_product_type: '',
        })
    const formAddNewShop = ref(null)

    const addNewShop = async () => {
      const isFormValid = await formAddNewShop.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/admin/shop',
        body: {
          shop_name: shopData.value.shop_name,
          shop_phone: shopData.value.shop_phone,
          shop_subdistrict: address.value.SUB_DISTRICT_NAME,
          shop_province: address.value.PROVINCE_NAME,
          shop_district: address.value.DISTRICT_NAME,
          shop_zipcode: address.value.ZIPCODE,
          shop_address: shopData.value.shop_address,
          shop_tax_number: shopData.value.shop_tax_number,
          shop_fax_number: shopData.value.shop_fax_number,
          shop_branch: shopData.value.shop_branch,
          shop_type: shopData.value.shop_type,
          shop_product_type: shopData.value.shop_product_type,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          clearData()
          emit('onAdd')
          emit('update:isAddNewShop', false)
        }
        loading.value = false
      })
    }

    const clearData = () => {
      shopData.value = {
        shop_name: '',
        shop_phone: '',
        shop_subdistrict: '',
        shop_province: '',
        shop_district: '',
        shop_zipcode: '',
        shop_address: '',
        shop_tax_number: '',
        shop_type: '',
        shop_branch: '',
        shop_fax_number: '',
        shop_product_type: '',
      }
      address.value = {
        SUB_DISTRICT_NAME: '',
        DISTRICT_NAME: '',
        PROVINCE_NAME: '',
        ZIPCODE: '',
      }
    }

    watch(() => props.isAddNewShop, value => {
      if (value) {
        clearData()
      }
    })
    return {
      shopData,
      formAddNewShop,
      locationList,
      required,
      loading,
      addNewShop,
      address,
    }
  },

}
</script>

<style scoped>

</style>
