<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <span>ข้อมูลร้านค้า</span>
        <v-spacer></v-spacer>
        <v-btn @click='isAddNewShop = true' color='primary'>เพิ่มร้านค้า</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getShop'
                        label='ค้นหาร้านค้า'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='shopList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          :footer-props='footer'
          loading-text='กำลังโหลดข้อมูล...'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.shop_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.shop_active`]='{ item }'>
          <StatusBlock :status='String(item.shop_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  @click='shopDetail = item;isShowDetail = true'
                  v-bind='attrs'
                  v-on='on'
              >
                <v-icon>{{ mdiTextBoxCheck }}</v-icon>
              </v-btn>
            </template>
            <span>รายละเอียด</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  @click='shopDetail = item;isShowDetailDialog = true'
                  v-bind='attrs'
                  v-on='on'
              >
                <v-icon>{{ mdiStoreOutline }}</v-icon>
              </v-btn>
            </template>
            <span>ดูร้านค้า</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='shopDetail = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='shopDetail = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.shop_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.shop_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewShop v-model='isAddNewShop' @onAdd='getShop'/>
    <ShopDetail v-model='isShowDetail' :shop-data='shopDetail'/>
    <SuspendShop v-model='isUpdateStatus' :shop-data='shopDetail' @onUpdate='getShop'/>
    <EditShop v-model='isEdit' :data-edit='shopDetail' @onUpdate='getShop'/>
    <ShopDetailDialog v-model="isShowDetailDialog" :shop-id="shopDetail.shop_id"/>
  </div>
</template>

<script>
import useShop from '@/views/administrator/shop/useShop'
import AddNewShop from '@/views/administrator/shop/AddNewShop'
import ShopDetail from '@/views/administrator/shop/ShopDetail'
import SuspendShop from '@/views/administrator/shop/SuspendShop'
import EditShop from '@/views/administrator/shop/EditShop'
import FilterStatus from '@/components/FilterStatus'
import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiPlus,
  mdiStoreOutline,
  mdiTextBoxCheck,
  mdiCheck,
} from '@mdi/js'
import StatusBlock from '@/components/StatusBlock'
import ShopDetailDialog from "@/views/administrator/shop/ShopDetailDialog";

export default {
  props: {},
  components: {
    ShopDetailDialog,
    StatusBlock,
    EditShop,
    FilterStatus,
    SuspendShop,
    AddNewShop,
    ShopDetail,
  },
  setup() {
    return {
      ...useShop(),
      mdiPencilOutline, mdiDeleteOutline, mdiPlus, mdiStoreOutline, mdiTextBoxCheck, mdiCheck,
    }
  },

}
</script>

<style scoped>

</style>
