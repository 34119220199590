import { api } from '@/services/api'
import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables'

export default function useShop() {

  const textSearch = ref('')
  const shopList = ref([])
  const loading = ref(false)
  const isAddNewShop = ref(false)
  const shopDetail = ref({})
  const isShowDetail = ref(false)
  const isUpdateStatus = ref(false)
  const isEdit = ref(false)
  const isShowDetailDialog = ref(false)
  const footer = ref(footerDataTables)
  const headers = ref([
    {
      text: '#',
      value: 'shop_id',
      width: '50',
      align: 'center',
    },
    {
      text: 'ชื่อร้านค้า',
      value: 'shop_name',
      width: '200',
    },
    {
      text: 'ที่อยู่ร้านค้า',
      value: 'shop_full_address',
      width: '300',
    },
    {
      text: 'เบอร์โทรศัพท์',
      value: 'shop_phone',
    },
    {
      text: 'สถานะ',
      value: 'shop_active',
      width: '100',
    },
    {
      text: 'ตัวเลือก',
      value: 'actions',
      width: '200',
      align: 'center',
    },
  ])
  const itemsPerPage = ref(10)
  const status = ref('1')

  const changeStatus = (id) => {
    status.value = id
    getShop()
  }

  const getShop = () => {
    loading.value = true
    api.get({
      path: '/admin/shops',
      param: `?search=${textSearch.value}&active=${status.value}`,
    }).then(({data}) => {
      shopList.value = data
      loading.value = false
    })
  }

  onMounted(() => {
    getShop()
  })

  return {
    isAddNewShop,
    isUpdateStatus,
    footer,
    headers,
    shopList,
    isEdit,
    shopDetail,
    changeStatus,
    isShowDetailDialog,
    itemsPerPage,
    isShowDetail,
    getShop,
    loading,
    textSearch,
  }
}
