var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"950","persistent":""},model:{value:(_vm.isShowDetail),callback:function ($$v) {_vm.isShowDetail=$$v},expression:"isShowDetail"}},[_c('v-card',{attrs:{"height":"700","max-height":"700"}},[_c('v-card-title',[_c('span',[_vm._v(" ข้อมูลของร้านค้า ")]),_c('v-spacer'),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.typeList,"no-data-text":"ไม่มีข้อมูล","label":"เลือกรายการ","filled":"","dense":"","item-text":"text","item-value":"value","hide-details":""},on:{"change":_vm.checkType},model:{value:(_vm.typeSelection),callback:function ($$v) {_vm.typeSelection=$$v},expression:"typeSelection"}})],1),_c('v-divider'),(_vm.typeSelection==='product')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.product_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.product_image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"elevation-4 rounded-lg my-1",attrs:{"src":item.product_image,"width":"50","height":"50"}})]}},{key:"item.shop_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.shop_id==1?("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg primary--text"):("v-chip-" + (_vm.$vuetify.theme.dark?'dark': 'light') + "-bg info--text"),attrs:{"color":item.shop_id == 1?'primary':'info',"small":""}},[_vm._v(" "+_vm._s(item.shop_id == 1 ? "Data center" : "ร้านค้า")+" ")])]}}],null,true)}):_vm._e(),(_vm.typeSelection==='warehouse')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.store_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,true)}):_vm._e(),(_vm.typeSelection==='employee')?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล..."},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.user_active",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":String(item.user_active)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.menuList=item.menus;_vm.isFixedRole = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTextBoxCheck))])],1)]}}],null,true)},[_c('span',[_vm._v("กำหนดสิทธิ์")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.userDetail = item;_vm.isEdit = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("แก้ไข")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.userDetail = item;_vm.isUpdateStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.user_active == 1 ? _vm.mdiDeleteOutline : _vm.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.user_active == 1 ? "ระงับ" : "ปกติ"))])])]}}],null,true)}):_vm._e(),_c('v-divider',{staticClass:"my-2"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit("update:isShowDetail",false)}}},[_vm._v("ปิด")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }